export const TAGS = {
    Beginner: 'Beginner',
    Intermediate: 'Intermediate',
    Advanced: 'Advanced',
    Online: 'Online',
    Free: 'Free',
    Monthly_sub: 'Monthly Subscription',
    Annual: 'Anual Payment',
    On_time: 'One Time Cost',
    Coding: 'Coding',
    No_Code: 'No-Code',
    Build_Websites: 'Build Websites',
    Build_landing_page: 'Build Landing Page',
    Design: 'Design',
    Learn_coding: 'Learn To Code',
    Game_assets: 'Game Assets',
    Free_assets: 'Free Assets',
    Game_dev: 'Game Development',
    Art: 'Art',
    Animation: 'Animation',
    Course: 'Course',
    Organization: 'Organization',
    Video: 'Video',
    Audio: 'Audio',
    Start_business: 'Start a business',
    Adobe: 'Adobe'
}